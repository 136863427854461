<template>
    <div class="chart-container">
        <v-overlay
                :opacity="0.15"
                :value="loader"
                absolute
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-card elevation="6" class="mx-auto" height="495px">
            <v-card-text>
                <div class="error-container" v-if="loadingError">
                    <v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
                </div>
                <v-row>
                    <v-col class="label black--text text--darken-1">{{ label2 }}</v-col>
                    <v-col class="label text-right blue--text text--darken-1 font-weight-bold d-flex flex-row align-end justify-end">{{ '$' + formatThisNumber(amount1, '0,0') }}</v-col>
                </v-row>
                <v-row>
                    <v-col class="label black--text text--darken-1">{{ label3 }}</v-col>
                    <v-col class="text-right label blue--text text--darken-1 font-weight-bold d-flex flex-row align-end justify-end">{{ '$' + formatThisNumber(amount2, '0,0') }}</v-col>
                </v-row>
                <v-row>
                    <v-col class="label black--text text--darken-1">{{ label4 }}</v-col>
                    <v-col class="text-right label blue--text text--darken-1 font-weight-bold d-flex flex-row align-end justify-end">{{ '$' + formatThisNumber(amount3, '0,0') }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {numberFormat} from "Helpers/helpers";
import {api} from "Api";

export default {
    name: "CommissionPayOut",
    props: ['conditions'],
    data() {
        return {
            errorMessage: null,
            label1: '',
            label2: '',
            label3: '',
            label4: '',
            loadingError: false,
            amount1: 0,
            amount2: 0,
            amount3: 0,
            loader: true,
        }
    },
    methods: {
        formatThisNumber(value, format) {
            return numberFormat(value, format)
        },
        getValues() {
            return new Promise((resolve, reject) => {
                const url = '/sparkline/card/gm-commission-payout'
                if(url) {
                    api
                        .get(url, {
                            params: {
                                conditions: this.conditions ? this.conditions : []
                            }
                        })
                        .then((response) => {
                            const data = response.data.data
                            resolve(data)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else {
                    reject('error')
                }
            })
        },
    },
    mounted() {
        this.getValues()
            .then((data) => {
                this.label1 = data.label1
                this.label2 = data.label2
                this.label3 = data.label3
                this.label4 = data.label4
                this.amount1 = data.amount1
                this.amount2 = data.amount2
                this.amount3 = data.amount3
                this.loader = false
            })
            .catch((error) => {
                this.loader = false
                this.loadingError = true
                this.errorMessage = error
            })
    }
}
</script>

<style scoped>
.label {
    font-weight: bold;
    font-size: 1.25em;
}
</style>